import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import TestCaseHeader from '../testCaseHeader';
import moment from 'moment';
import ReportDropdown from './ReportDropdown';
import DateFromToPicker from '../Dashboard/DateRangePicker';
import MUIButton from '../../shared/components/MUIButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Input, Modal, Pagination } from 'antd';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useAccountReportResponseMutation, useReportRequestGenerationMutation } from '../../redux/Reports/reports.api';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { ALERT_SEVERITY } from '../../shared/constants/constants';
import { logDOM } from '@testing-library/react';
import { useEffect } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import WrapperComponent from '../../shared/components/wrapperComponent';
import { useSelector } from 'react-redux';
import RtnDropdown from './RtnDropdown';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';
import PayaptLoader from '../../shared/components/Spinner';

const Reports = () => {
    const { TextArea } = Input;
    const Snackbar = useContext(SnackbarContext);
    const { customer } = useContext(DropdownValueContext);
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const { customerId } = useSelector((state) => state?.common);
    const [dateForReport, setDateForReport] = useState(moment());
    const [isDateSelected, setDateSelected] = useState(false);
    const [rtnNum, setRtnNum] = useState("");

    const onChangeDateFrom = (event) => {
        setDateForReport(event)
        if (event && event[0] && event[1]) {
            setDateSelected(true);
        } else {
            setDateSelected(false);
        }
    };

    const [reportType, setReportType] = useState('');
    const [reportDrop, setReportDrop] = useState();
    const [accReportRequestId, setAccReportRequestId] = useState();
    const [value, setValue] = useState([]);
    // const [customer, selectedCustomer] = useState();
    const [isModalVisible, setModalVisibility] = useState(false);
    const [reportRequestGeneration] = useReportRequestGenerationMutation();
    const [reportResponse, { isLoading, isSuccess }] = useAccountReportResponseMutation();
    const [isLoadingg, setIsLoading] = useState(false);

    const handleReportChange = (event) => {
        setReportType(event.target.value);
    };
    const onReportSelection = (event) => {
        setReportDrop(event)
        // setDateSelected(false);
    };

    const reportPayload = {
        fromDate: ("AADR" === reportDrop || "AATR" === reportDrop || "CADR" === reportDrop || "CATR" === reportDrop) ? dateForReport && dateForReport[0] ? moment(new Date(dateForReport[0])).format() : null : null,
        toDate: ("AADR" === reportDrop || "AATR" === reportDrop || "CADR" === reportDrop || "CATR" === reportDrop) ? dateForReport && dateForReport[1] ? moment(new Date(dateForReport[1])).format() : null : null,
        // fromDate: dateForReport[0] ? moment.utc(new Date(dateForReport[0])).format() : null,
        // toDate: dateForReport[1] ? moment.utc(new Date(dateForReport[1])).format() : null,
        reportType: reportDrop,
        customerId: (userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer,
        routingNumber: ("AADR" === reportDrop || "AATR" === reportDrop || "CADR" === reportDrop || "CATR" === reportDrop) ? rtnNum : null
    }
    // if (reportPayload.reportType === "EOD Report") {
    //     reportPayload.reportType = "eodReport";
    // } else if (reportPayload.reportType === "Account report request") {
    //     reportPayload.reportType = reportDrop;
    // }
    console.log("reportPayload", reportPayload);
    const responsePayload = {
        customerAccReportRequestId: accReportRequestId
    };

    useEffect(() => {
        if (isModalVisible !== false && accReportRequestId !== "undefined") {
            reportResponse(responsePayload).then(res => {
                // console.log("Res",res);
                setValue(res?.data?.data);
                // setModalVisibility(true);
            })
        }

    }, [accReportRequestId, isModalVisible])

    useEffect(() => {
        if (value?.length > 0) {
            setIsLoading(false)
        } else {
            setIsLoading(true)
        }
    }, [value])

    const handleGenerate = async (isVisible) => {
        if (reportDrop === undefined) {
            Snackbar.displayMsg("Please Select Report Type", ALERT_SEVERITY.ERROR);
            return; // Stop further execution
        }
        if (("AADR" === reportDrop || "AATR" === reportDrop || "CADR" === reportDrop || "CATR" === reportDrop) && !isDateSelected) {
            Snackbar.displayMsg("Please Select Start & End date", ALERT_SEVERITY.ERROR);
            return;
        } else {
            await reportRequestGeneration(reportPayload).then(res => {
                // console.log("Res", res);
                if (res?.data?.status === "success") {
                    Snackbar.displayMsg("Account Report Request Generated Successfully", ALERT_SEVERITY.SUCCESS);
                    setAccReportRequestId(res?.data?.customerAccReportRequestId);
                    setTimeout(() => {
                        setModalVisibility(isVisible);
                    }, 5000);
                    //    setTimeout(()=>setModalVisibility(!isVisible),2500);
                    //    setModalVisibility(isVisible);
                } else {
                    Snackbar.displayMsg("Failed to generated account report request", ALERT_SEVERITY.ERROR);
                }
            })
        }

        // setModalVisibility(isVisible)
    };
    // const disabledDate = (current) => {
    //     //   return current && current > moment().endOf('day');
    //     const today = moment().endOf('day');
    //     const sevenDaysAgo = today.clone().subtract(91, 'days');
    //     return current && (current > today || current < sevenDaysAgo);
    // };

    const disabledDate = (current) => {
        const today = moment().startOf('day'); // Use startOf('day') to avoid including today
        const sevenDaysAgo = today.clone().subtract(90, 'days');
        return current && (current >= today || current < sevenDaysAgo);
    };


    const [currentPage, setCurrentPage] = useState(1); // Track the current page
    const itemsPerPage = 1; // Define the number of items per page
    // Assuming your data is in this format or shape
    // const data = []; // Replace this with your actual data array

    // Calculate the start and end indexes of items to display based on the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const changePage = (page) => {
        setCurrentPage(page);
    };
    const xmlData = value[startIndex]?.response;

    const onCancel = () => {
        setModalVisibility(false)
        setReportDrop();
        setDateSelected(false);
        setValue([]);
    };

    const downloadFile = () => {
        if (value?.length > 0) {
            const element = document.createElement("a");
            const file = new Blob([xmlData], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = `${reportDrop}`
            document.body.appendChild(element);
            element.click();
            setModalVisibility(true)
        } else {
            Snackbar.displayMsg("No data to download",ALERT_SEVERITY.ERROR);
        }

    };

    const handleRefreshButton = () => {
        reportResponse(responsePayload).then(res => {
            setValue(res?.data?.data);
            // setModalVisibility(true);
        })
    }
    const onTestCaseCustomerSelection = (event) => {
        selectedCustomer(event);
    };

    const onRTNSelection = (e) => {
        setRtnNum(e)
    };

    return (
        <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
            <TestCaseHeader
                title="Account Reporting Request Camt.060"
                showTestCustomer={{
                    show: true,
                    // onChange: onTestCaseCustomerSelection,
                    // value: customer
                }}
            // showDateFromToPicker={{ show: true, onChange: onChangeDateFrom, value: dateForReport, }}
            // displayInLine={true}
            />

            {/* <Box mt={"2%"} sx={{ px: '2.1%' }}>
                <Box>
                    <Grid container>
                        <Grid item width={"40%"} mt={1}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                                Select Report Type
                            </Typography>
                        </Grid>
                        <Grid item>
                            <FormControl component="fieldset">
                                <RadioGroup row aria-label="payment-method" name="payment-method" value={reportType} onChange={handleReportChange}>
                                    <FormControlLabel value="EOD Report" control={<Radio />} label="EOD Report" />
                                    <FormControlLabel value="Account report request" control={<Radio />} label="Account report request" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box> */}

            {/* {"Account report request" === reportType && */}
            <Box sx={{ px: '2.1%' }}>
                <Box>
                    <Grid container>
                        <Grid item width={"40%"} mt={1}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                                Select Report Type
                            </Typography>
                        </Grid>
                        <Grid item mt={3}>
                            <ReportDropdown onChangeReport={onReportSelection} value={reportDrop} />
                        </Grid>
                    </Grid>
                </Box>

            </Box>
            {/* // } */}

            {("AADR" === reportDrop || "AATR" === reportDrop || "CADR" === reportDrop || "CATR" === reportDrop) &&
                <Box mt={"2%"} sx={{ px: '2.1%' }}>
                    <Box>
                        <Grid container>
                            <Grid item width={"40%"} mt={1}>
                                <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                                    Select Date
                                </Typography>
                            </Grid>
                            <Grid item>
                                <DateFromToPicker onChangeDateFrom={onChangeDateFrom} value={dateForReport} disabledDate={disabledDate} />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box mt={"2%"}>
                        <Box>
                            <Grid container>
                                <Grid item width={"40%"} mt={1}>
                                    <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                                        Select Routing Number
                                    </Typography>
                                </Grid>
                                <Grid item mt={3}>
                                    {/* <ReportDropdown onChangeReport={onReportSelection} value={reportDrop} /> */}
                                    <RtnDropdown onChangeRtn={onRTNSelection} value={rtnNum} customer={(userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer}
                                        reportName={reportDrop === "CADR" || reportDrop === "CATR" ? reportDrop : null}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            }


            <Box mt={"23%"} sx={{ display: "flex", px: '2.1%' }}>
                <MUIButton
                    startIcon={<CheckCircleIcon style={{ fontSize: 18, }} />}
                    title="Generate"
                    onClick={handleGenerate}
                />
            </Box>

            <Modal
                onCancel={onCancel}
                open={isModalVisible} footer={[]}
                closeIcon={<CancelOutlinedIcon style={{ color: "#001A11", backgroundColor: "white" }} />}
                destroyOnClose
                width={"70%"}
                maskClosable={false}
            >
                <Box>
                    <TestCaseHeader title={`Camt.052-${reportDrop}`}

                        showFileUpload={{
                            show: true,
                            customIcon: <FileDownloadIcon />,
                            onClick: () => downloadFile()
                        }}
                        showRefresh={{
                            show: false,
                            onClick: handleRefreshButton
                        }}
                    />
                    {/* <WrapperComponent isSuccess={isSuccess} isFetching={isLoading}> */}
                    <div style={{ marginTop: 1}}>
                        {isLoadingg && <PayaptLoader />}
                        <TextArea
                            className="xml-txt-area"
                            rows={15}
                            readOnly
                            style={{ height: '100%' }}
                            value={value?.length > 0 ? value[startIndex]?.response : 'IN PROGRESS'} // Display the response based on the current page
                        // value={value === null ? "NO DATA" : value}
                        />


                        <div style={{ marginTop: 4, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                            {/* {("AADR" === reportDrop || "AATR" === reportDrop || "CADR" === reportDrop || "CATR" === reportDrop || "IATR" === reportDrop) && ( */}
                            <Pagination
                                current={currentPage}
                                pageSize={itemsPerPage}
                                total={value?.length}
                                onChange={changePage}
                                hideOnSinglePage={true}
                            />
                            {/* )} */}
                        </div>

                    </div>
                    {/* </WrapperComponent> */}
                </Box>

            </Modal>
        </Box>
    )
}

export default Reports;
