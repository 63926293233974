import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Modal, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import TestCaseMessages from './TestCaseMessages';
import ViewTestCaseDetails from '../../components/messages/testcaseDetails';

import { collapseSideMenu, updateSelectedTestCasePayload } from '../../redux/common/common.slice';
import { useAddTestRunsMutation } from '../../redux/testRuns/testRuns.api';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { ALERT_SEVERITY } from '../../shared/constants/constants';
import TestCaseHeader from '../../components/testCaseHeader';
import XTwoWayLabelSwitch from '../../shared/components/XTwoWayLabelSwitch';
import useModal from '../../shared/hooks/useModal.hook';
import CreateBatchFile from '../BatchFiles/create/CreateBatchFile.testScenarios';
import BatchFiles from '../BatchFiles/BatchFiles';
import { useCreateBatchFileMutation, useCreateSystemBatchFileMutation, useExecuteBatchMutation, useExecuteBatchQuery, useExistingcustomerTestBatchCreateMutation, useUpdateBatchFileMutation } from '../../redux/batchFiles/batchFile.api';
import ModalComponent from './ModalComponent';
import { BatchFileMsg } from '../../shared/constants/AlertMessages';
import { generateCurrentEpochTimeValue } from '../../shared/utils';
import PayaptLoader from '../../shared/components/Spinner';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useSelectAPICallMutation } from '../../redux/testCases/testCases.api';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        height: `calc(100vh - 100ox)`
    }
};

function TestCasesContainer(props) {
    //
    const { messageTypePayload, title, subtitle, } = props
    // console.log("messageTypePayload", messageTypePayload);
    const { testType: qpTestType } = useParams();
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const dispatch = useDispatch();
    const Snackbar = useContext(SnackbarContext);
    const { customer } = useContext(DropdownValueContext);
    const { customerTestScenarioName } = useSelector((state) => state?.common)
    const { customerId } = useSelector((state) => state?.common);
    // console.log("customerTestScenarioName",customerTestScenarioName);
    const { open: createBatchFileEl, openModal: createBatchFileOpenModal, closeModal: createBatchFileCloseModal } = useModal();
    const [addTestRuns, { isLoading }] = useAddTestRunsMutation();
    const [executeBatch, { isLoading: isexecuteBatchLoading }] = useExecuteBatchMutation();    //
    const [headerTitle, setHeaderTitle] = useState("");
    const [headerSubTitle, setHeaderSubTitle] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [isBatchMode, setIsBatchMode] = useState(false);
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    const [selectedTestScenarios, setSelectedScenarios] = useState({});
    // console.log("selectedTestScenariosOut", selectedTestScenarios);
    const [isTestcaseDetailsVisible, setTestcaseDetailsVisibility] = useState(false);
    const [selectedTestcase, setSelectedTestcase] = useState({});
    const [batchContext, setBatchContext] = useState({
        batchId: null,
        batchType: 'C', //S for system generated,
        open: false,
        isUpdate: false
    })
    const [selectedBatch, setSelectedBatch] = useState({})
    const [batchContextToRun, setBatchContextToRun] = useState([]);
    const [displayAllBatches, setDisplayAllBatches] = useState(false);
    const [createCustomerBatchFile] = useCreateBatchFileMutation();
    const [existingCustomerBatch] = useExistingcustomerTestBatchCreateMutation();
    const [updateBatchFile] = useUpdateBatchFileMutation();
    const [createSystemBatchFile] = useCreateSystemBatchFileMutation();
    const [selectAPICall, { data }] = useSelectAPICallMutation();
    const [isLoadingg, setIsLoading] = useState(false);
    
    var testCaseSize;
    React.useEffect(() => {
        setSearchTerm("");
        setSelectedBatch({});
        setSelectedScenarios({});
        setBatchContext({ batchId: null, batchType: 'C', open: false })
        setIsBatchMode(false)
        if (qpTestType === "SA") {
            setHeaderTitle("Customer Credit Transfer");
            setHeaderSubTitle("Details of test cases for customer credit transfer");
        } else {
            setHeaderTitle("Customer Credit Transfer");
            setHeaderSubTitle("Details of test cases for customer credit transfer");
        }
    }, [qpTestType])

    //
    const executeSelectedBatch = async () => {
        try {
            const res = await executeBatch({ batchIds: batchContextToRun });
            Snackbar.displayMsg(BatchFileMsg.EXECUTE_BATCH_SUCCESS, ALERT_SEVERITY.SUCCESS);
        } catch (error) {
            Snackbar.displayMsg(BatchFileMsg.EXECUTE_BATCH_FAIL, ALERT_SEVERITY.ERROR);
        }
    }
    useEffect(() => {
        setSelectedScenarios({})
    }, [messageTypePayload])
    const createSystemBatch = async (batchFileDetails) => {
        setIsLoading(true)
        batchFileDetails.batchType = 'C';
        const payload = getBatchPayload(batchFileDetails);
        try {
            await createSystemBatchFile({ ...payload }).then(res => {
                if (res?.error?.data?.debugMessage === "Batch name already exist") {
                    setIsLoading(false)
                    Snackbar.displayMsg("Batch name already exist", ALERT_SEVERITY.ERROR);
                    

                } else {
                    Snackbar.displayMsg(BatchFileMsg.CREATE_BATCH_FILE_SUCCESS, ALERT_SEVERITY.SUCCESS);
                    setIsLoading(false)
                    setBatchContext({
                        ...batchContext,
                        open: false
                    })
                    setSelectedScenarios({});
                    setSelectedTestcase({})
                }
            })
            // Snackbar.displayMsg(BatchFileMsg.CREATE_BATCH_FILE_SUCCESS, ALERT_SEVERITY.SUCCESS);

        } catch (error) {
            Snackbar.displayMsg(BatchFileMsg.CREATE_BATCH_FAIL, ALERT_SEVERITY.ERROR);
        }
    };

    const getTestDataPayload = (payload, customerTestCaseDTO) => {
        if (batchContext?.batchType === 'C') {
            payload.testCaseId = _.map(customerTestCaseDTO, c => c?.customerTestCaseId);
        } else {
            const testCaseIds = [];
            _.map(customerTestCaseDTO, c => {
                testCaseIds.push({
                    testcaseId: c?.customerTestCaseId,
                    testDatas: c?.customerTestDataId
                })
            })
            payload.testCaseIds = testCaseIds
        }
        return payload;
    }
    const getBatchPayload = (batchFileDetails) => {
        batchFileDetails.testType = qpTestType;
        const isSelectingByScenarioCheckboxes = _.filter(
            Object.keys(selectedTestScenarios),
            (k) =>
                selectedTestScenarios[k].isAllTestcasesSelected
        );
        const isSelectingByTestCasesCheckboxes = _.filter(
            Object.keys(selectedTestScenarios),
            (k) =>
                !selectedTestScenarios[k].isAllTestcasesSelected &&
                selectedTestScenarios[k].selectedKeys?.length > 0
        );
        const customerTestCaseDTO = []
        isSelectingByTestCasesCheckboxes?.map(
            (xId) => _.map(selectedTestScenarios[xId]?.selectedKeys, k => customerTestCaseDTO.push(k))
        );
        if (isSelectingByScenarioCheckboxes?.length > 0) {
            const payload = {
                ...batchFileDetails,
                customerId: userRole?.name === 'Admin' || userRole?.name === 'Tester' ? customerId : customer,
                testScenarioId: isSelectingByScenarioCheckboxes?.map(
                    (xId) => xId
                )
            };
            return getTestDataPayload(payload, customerTestCaseDTO);
        }
        if (isSelectingByTestCasesCheckboxes?.length > 0 && isSelectingByScenarioCheckboxes?.length <= 0) {
            const payload = {
                ...batchFileDetails,
                customerId: userRole?.name === 'Admin' || userRole?.name === 'Tester' ? customerId : customer,
                // testCaseId: _.map(customerTestCaseDTO, c => c?.customerTestCaseId),
                // reviewed: true,
            }
            return getTestDataPayload(payload, customerTestCaseDTO);
        }
    }
    const createCustomerBatch = async (batchFileDetails, batchId) => {

        if (batchId) {
            batchFileDetails.batchType = 'C';
            batchFileDetails.batchId = batchId
            const payload = getBatchPayload(batchFileDetails);
            try {
                const res = await existingCustomerBatch({ ...payload });
                Snackbar.displayMsg(BatchFileMsg.CREATE_BATCH_FILE_SUCCESS, ALERT_SEVERITY.SUCCESS);
                setBatchContext({
                    ...batchContext,
                    batchId: res?.data?.customerbatchId,
                    open: false,

                })
                setSelectedScenarios({});
                dispatch(collapseSideMenu(false))
            } catch (error) {
                Snackbar.displayMsg(BatchFileMsg.CREATE_BATCH_FAIL, ALERT_SEVERITY.ERROR);
            }
        }
        else {

            batchFileDetails.batchType = 'C';
            const payload = getBatchPayload(batchFileDetails);
            if (Array.isArray(payload.testCaseId) && payload.testCaseId.length === 0) {
                payload.testCaseId = null;
            }
            // console.log("payloaddddd",payload);
            try {
                const res = await createCustomerBatchFile({ ...payload });
                if (res?.error?.data?.message === "Batch name already exist") {
                    Snackbar.displayMsg("Batch name already exist", ALERT_SEVERITY.ERROR)
                } else {
                    Snackbar.displayMsg(BatchFileMsg.CREATE_BATCH_FILE_SUCCESS, ALERT_SEVERITY.SUCCESS);
                    setBatchContext({
                        ...batchContext,
                        open: false,
                        batchId: res?.data?.customerbatchId
                    })
                    setSelectedScenarios({});
                    dispatch(collapseSideMenu(false))
                }


            } catch (error) {
                Snackbar.displayMsg(BatchFileMsg.CREATE_BATCH_FAIL, ALERT_SEVERITY.ERROR);
            }
        }

    };

    const updateBatchFileDetails = async (batchFileDetails) => {
        try {
            const res = await updateBatchFile({ ...batchFileDetails, batchId: batchContext?.selectedBatch?.customerTestRunBatchId });
            Snackbar.displayMsg(BatchFileMsg.UPDATE_BATCH_FILE_SUCCESS, ALERT_SEVERITY.SUCCESS);
            setBatchContext({
                ...batchContext,
                open: false,
            })
            dispatch(collapseSideMenu(true))
        } catch (error) {
            Snackbar.displayMsg(BatchFileMsg.UPDATE_BATCH_FAIL, ALERT_SEVERITY.ERROR);
        }
    };

    const handleSwitchToggle = (xEvent) => {

        console.log("is batch selected", xEvent.target.checked);
        setIsBatchMode(xEvent.target.checked);
        setSelectedBatch({});
        setSelectedScenarios({})
        if (xEvent.target.checked === false) {
            setBatchContext({
                ...batchContext,
                isUpdate: false
            })
            // console.log("batch context ",...batchContext);

        }
    };
    const setTestcaseDetailsVisible = (visible) => {
        dispatch(collapseSideMenu(visible))
        setTestcaseDetailsVisibility(visible);
        createBatchFileCloseModal();
        if (isBatchMode) {
            setSelectedTestcase({})
        }
    };
    const handleCreateClick = (event, batch) => {
        setTestcaseDetailsVisibility(false);
        dispatch(collapseSideMenu(false))
        setBatchContext({
            ...batchContext,
            batchType: 'C',
            open: true,
            selectedBatch: batch
        })
        // createBatchFileOpenModal();
    };
    const handleBatchPreviewClick = (previewEl, batch) => {
        setTestcaseDetailsVisibility(false);
        dispatch(collapseSideMenu(false));
        setBatchContext({
            ...batchContext,
            batchType: 'C',
            open: previewEl,
            selectedBatch: batch,
            isUpdate: true
        })
        // createBatchFileOpenModal();
    };
    const handleModalClose = () => {
        setBatchContext({
            ...batchContext,
            batchType: 'C',
            open: false
        })
    }

    const closeBatchModal = () => {
        setBatchContext({
            ...batchContext,
            open: false,
            selectedBatch: null
        })
        dispatch(collapseSideMenu(false))
    }
    const handleSearchOnChange = (xValue) => {
        setSearchTerm(xValue);
    };

    const sendTestCasesToRun = async (payload) => {
        console.log('payloadRUN', { payload });
        try {
            const res = await addTestRuns(payload);
            if (res?.data?.messasge === "Customer test run addded succesfully") {
                setSelectedScenarios([])  //for checkbox after run it will be like
                setSelectedTestcase({});
                dispatch(updateSelectedTestCasePayload([]))
                Snackbar.displayMsg(res?.data?.messasge, ALERT_SEVERITY.SUCCESS);
            }
        } catch (error) {
            Snackbar.displayMsg(JSON.stringify(error), ALERT_SEVERITY.ERROR);
        }
    }
    const runTestCases = async () => {
        const objKeys = Object.keys(selectedTestScenarios);

        if (objKeys?.length === 1 && selectedTestScenarios[objKeys[0]]?.selectedKeys?.length === 1) {
            const isSelectingByScenarioCheckboxes = _.filter(
                Object.keys(selectedTestScenarios),
                (k) =>
                    selectedTestScenarios[k].isAllTestcasesSelected
            );
            const isSelectingByTestCasesCheckboxes = _.filter(
                Object.keys(selectedTestScenarios),
                (k) =>
                    !selectedTestScenarios[k].isAllTestcasesSelected &&
                    selectedTestScenarios[k].selectedKeys?.length > 0
            );
            const customerTestCaseDTO = [];
            isSelectingByTestCasesCheckboxes?.map(
                (xId) => _.map(selectedTestScenarios[xId]?.selectedKeys, k => customerTestCaseDTO.push(k))
            );
            if (isSelectingByScenarioCheckboxes?.length > 0) {
                const payload = {
                    customerTestRunScenarioListDTO: isSelectingByScenarioCheckboxes?.map(
                        (xId) => ({
                            customerTestCaseScenarioId: xId,
                            reviewed: true,
                        })
                    ),
                };
                payload.customerTestRunScenarioListDTO.push({
                    customerTestCaseScenarioId: null,
                    customerTestCaseDTO: [...customerTestCaseDTO],
                    reviewed: true,
                })
                sendTestCasesToRun({ ...payload })
                return;
            }
            if (isSelectingByTestCasesCheckboxes?.length > 0 && isSelectingByScenarioCheckboxes?.length <= 0) {
                const payload = {
                    customerTestRunScenarioListDTO: [{
                        customerTestCaseScenarioId: null,
                        customerTestCaseDTO: [...customerTestCaseDTO],
                        reviewed: true,
                    }]
                }
                sendTestCasesToRun({ ...payload })
                return;
            }
            return;
        } else {
            if (isBatchMode) {
                executeSelectedBatch();
            } else {
                setBatchContext({
                    ...batchContext,
                    batchType: 'S',
                    open: true
                })
            }
        }
    }

    const openBatchTestcaseDetails = (e, testcase) => {
        setBatchContext({
            ...batchContext,
            open: false
        })
        dispatch(collapseSideMenu(true));
        setSelectedTestcase({ ...testcase, customerTestCaseId: testcase.testCaseId });
        setTestcaseDetailsVisibility(true);
    }

    const onBatchSelectionChange = (checked, batch) => {
        const updatedBatches = [...batchContextToRun];
        if (checked) {
            updatedBatches.push({
                batchId: batch?.customerTestRunBatchId,
                caseAndDatas: null,
            })
        } else {
            _.remove(updatedBatches, b => b.batchId === batch?.customerTestRunBatchId);
        }
        setBatchContextToRun(updatedBatches);
    }

    const onBatchTestDataSelectionChange = (e, testdata, testcase, batch) => {
        const updatedBatches = [...batchContextToRun];
        let batchIndex = _.findIndex(updatedBatches, b => b.batchId === batch?.customerTestRunBatchId);
        if (batchIndex < 0) {
            updatedBatches.push({
                batchId: batch?.customerTestRunBatchId,
                caseAndDatas: null,
            })
            batchIndex = _.size(updatedBatches) - 1;
        }
        const caseAndDatas = updatedBatches[batchIndex]?.caseAndDatas || [];
        const testcaseIndex = _.findIndex(caseAndDatas, b => b.testCases === testcase?.testCaseId);
        if (e.target.checked) {
            if (testcaseIndex >= 0) {
                caseAndDatas[testcaseIndex]?.testDatas.push(testdata?.testDataId)
            } else {
                caseAndDatas.push({
                    testCases: testcase?.testCaseId,
                    testDatas: [testdata?.testDataId],
                })
            }

        } else {
            if (testcaseIndex >= 0) {
                _.remove(caseAndDatas[testcaseIndex]?.testDatas, b => b === testdata?.testDataId);
            }
        }
        updatedBatches[batchIndex].caseAndDatas = caseAndDatas;
        setBatchContextToRun(updatedBatches)
    }
    const condGridValue = (isTestcaseDetailsVisible || (batchContext.open && batchContext.batchType === 'C')) ? 7 : 12;
    const condGridDirection = (isTestcaseDetailsVisible || (batchContext.open && batchContext.batchType === 'C')) ? "row" : "column";

    const getTotalTestCasesCount = () => {
        // console.log('selectedTestScenarios-1',selectedTestScenarios);
        let size = 0;
        _.forEach(selectedTestScenarios, (val, key) => {
            if (!val?.isAllTestcasesSelected) {
                // console.log('selectedTestScenarios-2',val);
                size = size + _.size(val?.selectedKeys)
            } else {
                // console.log('selectedTestScenarios-3',val);
                size = size + val?.totalTescaseCount
            }
        })
        testCaseSize = size;
        return size;

    }
    const setBatchselection = (batch) => {
        setSelectedBatch(batch);
        setBatchContext({
            ...batchContext,
            batchType: 'C',
            open: false,
            selectedBatch: {},
            isUpdate: false
        })
    }

    const onTestCaseCustomerSelection = (event) => {
        selectedCustomer(event);
    }

    var selectedTestCasePayload = [];
    var scenario
    {
        Object.keys(selectedTestScenarios).map(key => (
            // console.log("KEY DOWN",key)
            scenario = key
        ))
    }

    const objectLength = Object.keys(selectedTestScenarios).length == 1;
    // console.log("Length Of the Object", objectLength);
    const objectKey = Object.keys(selectedTestScenarios)[0];
    const selectedKeys = selectedTestScenarios[objectKey]?.selectedKeys;
    // console.log("selectedKeys", selectedKeys);
    {
        selectedKeys && selectedKeys.map((key, index) => (
            // console.log("selectedKeys",key.customerTestCaseId)
            selectedTestCasePayload.push(key.customerTestCaseId)
        ))
    }

    const payload = {
        scenarioId: selectedTestScenarios[objectKey]?.isAllTestcasesSelected ? scenario : null,
        customerTestCaseId: selectedTestScenarios[objectKey]?.isAllTestcasesSelected ? null : selectedTestCasePayload,
        testCaseType: ""
    }
    console.log("PAYLOAD TEST", payload);
    //  useEffect(() => {
    //     selectAPICall(payload)
    //  },[])
    //  console.log("DATAA",data);
    // console.log("selectedTestCasePayload", selectedTestCasePayload);

    const handleDownloadClick = async () => {
        try {
            if (objectLength) {
                await selectAPICall(payload).unwrap().then((res) => {
                    console.log("RESSSSChe", res);
                    if (res.mfTestCaseAPI === true) {
                        handleDownload1Click()
                    }
                    if (res.mfTestDataAPI === true) {
                        handleDownload2Click()
                    } if (res.saAPI === true) {
                        handleDownload3Click()
                    }
                })
            }
            else {
                Snackbar.displayMsg("Please do not select both Inbound and Outbound cases together while downloading", ALERT_SEVERITY.ERROR)
            }
        }
        catch (e) {
            console.log("eror file download", e);
            if (e?.status === 400) {
                Snackbar.displayMsg(e?.data?.message, ALERT_SEVERITY.ERROR);
            } else {
                Snackbar.displayMsg("Failed to Download the file", ALERT_SEVERITY.ERROR);
            }
        }

    }
    const handleDownload3Click = async () => {
        setIsDownloadLoading(true)
        const token = await localStorage.getItem('jwttoken');
        await fetch(`${window.REACT_APP_BASE_URL}Payapt/api/v1/TestCaseUpload/downloadCustomerTestCaseForSA`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    scenarioId: selectedTestScenarios[objectKey]?.isAllTestcasesSelected ? scenario : null,
                    customerTestCaseId: selectedTestScenarios[objectKey]?.isAllTestcasesSelected ? null : selectedTestCasePayload,
                    testCaseType: "SA"
                })
            })

            .then((response) => response.blob())
            .then((blob) => {
                console.log('blob', JSON.stringify(blob));
                console.log('response', JSON.stringify(blob));
                // Create blob link to download  
                setIsDownloadLoading(false)
                const url = window.URL.createObjectURL(new Blob([blob]))
                // const filename = url.split('/').pop()
                // console.log("filename:",filename);
                const link = document.createElement('a');
                const fileName = messageTypePayload + "_" + customerTestScenarioName + "_" + "SA" + "_" + generateCurrentEpochTimeValue()
                link.href = url;
                link.setAttribute('download', `${fileName}.xlsx`);
                // Append to html link element page    
                document.body.appendChild(link); // Start download    
                link.click();// Clean up and remove the link    
                link.parentNode.removeChild(link);
            })

    }

    const handleDownload2Click = async () => {
        setIsDownloadLoading(true)
        const token = await localStorage.getItem('jwttoken');
        await fetch(`${window.REACT_APP_BASE_URL}Payapt/api/v1/TestCaseUpload/downloadCustomerTestData`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    scenarioId: selectedTestScenarios[objectKey]?.isAllTestcasesSelected ? scenario : null,
                    customerTestCaseId: selectedTestScenarios[objectKey]?.isAllTestcasesSelected ? null : selectedTestCasePayload,
                    testCaseType: "MF"
                })
            })
            .then((response) => response.blob())
            .then((blob) => {
                console.log('blob', JSON.stringify(blob));
                // Create blob link to download  
                setIsDownloadLoading(false)
                const url = window.URL.createObjectURL(new Blob([blob]))
                const link = document.createElement('a');
                link.href = url;
                const fileName = messageTypePayload + "_" + customerTestScenarioName + "_" + "MF TestData" + "_" + generateCurrentEpochTimeValue()
                link.setAttribute('download', `${fileName}.xlsx`);
                // Append to html link element page    
                document.body.appendChild(link); // Start download    
                link.click();// Clean up and remove the link    
                link.parentNode.removeChild(link);
            })
        // handleDownload3Click()
    }

    // console.log("scenario", scenario);
    const handleDownload1Click = async () => {
        setIsDownloadLoading(true)
        {
            Object.keys(selectedTestScenarios).map(key => (
                // console.log("KEY DOWN",key)
                scenario = key
            ))
        }

        const token = await localStorage.getItem('jwttoken');
        try {
            await fetch(`${window.REACT_APP_BASE_URL}Payapt/api/v1/TestCaseUpload/downloadCustomerTestCase`,
                // TestCaseUpload/downloadCustomerTestData`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        scenarioId: selectedTestScenarios[objectKey]?.isAllTestcasesSelected ? scenario : null,
                        customerTestCaseId: selectedTestScenarios[objectKey]?.isAllTestcasesSelected ? null : selectedTestCasePayload,
                        testCaseType: "MF"
                    })
                })
                .then((response) => response.blob())
                .then((blob) => {
                    console.log('blob', JSON.stringify(blob));
                    // Create blob link to download 
                    setIsDownloadLoading(false)
                    const url = window.URL.createObjectURL(new Blob([blob]))
                    const link = document.createElement('a');
                    link.href = url;
                    const fileName = messageTypePayload + "_" + customerTestScenarioName + "_" + "MF TestCase" + "_" + generateCurrentEpochTimeValue()
                    link.setAttribute('download', `${fileName}.xlsx`);
                    // Append to html link element page    
                    document.body.appendChild(link); // Start download    
                    link.click();// Clean up and remove the link    
                    link.parentNode.removeChild(link);
                })
        } catch (error) {
            console.log("ERROR", error);
        }
    }
    return (
        <Box sx={styles.container}>
            {isLoading && <PayaptLoader sx={{ position: "absolute", left: "55%" }} />}
            {isexecuteBatchLoading && <PayaptLoader sx={{ position: "absolute", left: "55%" }} />}
            {isDownloadLoading && <PayaptLoader sx={{ position: "absolute", left: "55%" }} />}


            <Grid container direction={condGridDirection}>
                <Grid item xs={condGridValue} md={condGridValue}>
                    <TestCaseHeader
                        title={title}
                        subtitle={subtitle}
                        showRun={{
                            show: true,
                            disabled: isBatchMode ? _.isEmpty(batchContextToRun) : _.isEmpty(selectedTestScenarios) || !getTotalTestCasesCount(),
                            onClick: runTestCases
                        }}
                        showCreate={{
                            show: !isBatchMode,
                            onClick: handleCreateClick,
                            // disabled: _.isEmpty(selectedTestScenarios) && !getTotalTestCasesCount()
                            disabled: isBatchMode ? _.isEmpty(batchContextToRun) : _.isEmpty(selectedTestScenarios) || !getTotalTestCasesCount(),

                        }}
                        showSearch={{
                            show: !isBatchMode,
                            value: searchTerm,
                            onChange: handleSearchOnChange
                        }}
                        showTestCustomer={{
                            show: true,
                        }}
                        showFileDownload={{
                            show: (userRole?.name === 'Admin' || userRole?.name === 'Tester') ? false : true,
                            onClick: handleDownloadClick,
                            disabled: _.isEmpty(selectedTestScenarios) || !getTotalTestCasesCount(),
                            customIcon: <FileDownloadIcon />
                        }}
                    >
                        {/* <Box sx={{ display: "flex", }}>
                            <XTwoWayLabelSwitch
                                primary={"Test Cases"} secondary={"Batch Files"} checked={isBatchMode} onChange={handleSwitchToggle}
                            />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {isBatchMode &&
                                <XTwoWayLabelSwitch
                                    primary={"My Batches"} secondary={"All Batches"} checked={displayAllBatches} onChange={(e) => {
                                        setDisplayAllBatches(e.target.checked);
                                        setBatchContextToRun([]);
                                    }}
                                />
                            }
                        </Box> */}
                    </TestCaseHeader>
                    {/* {!isBatchMode && */}
                    <Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', }} className='standalone_table'>
                            <Typography sx={{ textAlign: 'left', fontSize: 14 }}>Total test cases selected: <b>{getTotalTestCasesCount()}</b></Typography>
                        </Box>
                        <TestCaseMessages
                            selectedTestScenarios={selectedTestScenarios}
                            setSelectedScenarios={setSelectedScenarios}
                            isTestcaseDetailsVisible={isTestcaseDetailsVisible}
                            setTestcaseDetailsVisibility={setTestcaseDetailsVisibility}
                            selectedTestcase={selectedTestcase}
                            setSelectedTestcase={setSelectedTestcase}
                            searchTerm={searchTerm}
                            // customer={customer ? customer : customerId}
                            messageTypePayload={messageTypePayload}
                        />
                    </Box>
                    {/* } */}
                    {/* {isBatchMode &&
                        <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', }} className='standalone_table'>
                                <Typography sx={{ textAlign: 'left', fontSize: 14 }}>Selected Batch Files: <b>{_.size(batchContextToRun)}</b></Typography>
                            </Box>

                            <BatchFiles
                                selectedBatch={selectedBatch}
                                setSelectedBatch={setBatchselection}
                                onPreview={handleBatchPreviewClick}
                                openBatchTestcaseDetails={openBatchTestcaseDetails}
                                type={qpTestType}
                                onBatchSelectionChange={onBatchSelectionChange}
                                onBatchTestDataSelectionChange={onBatchTestDataSelectionChange}
                                batchContextToRun={batchContextToRun}
                                displayAllBatches={displayAllBatches}
                                customer={customer ? customer : 0}
                            />
                        </Box>
                    } */}
                </Grid>

                {batchContext.batchType === 'C' && batchContext.open && <Grid item xs={5} sx={{ borderLeft: "1px solid #808080" }}>
                    <CreateBatchFile
                        onCancel={closeBatchModal}
                        selectedTestScenarios={selectedTestScenarios}
                        createBatch={isBatchMode ? updateBatchFileDetails : createCustomerBatch}
                        selectedBatch={batchContext?.selectedBatch}
                        isUpdateScenario={batchContext?.isUpdate}
                    />
                </Grid>}
                {isTestcaseDetailsVisible && (
                    <Grid item xs={5}>
                        <ViewTestCaseDetails
                            testcase={selectedTestcase}
                            onClose={setTestcaseDetailsVisible}
                            isBatchMode={isBatchMode}
                        />
                    </Grid>
                )}
            </Grid>

            {batchContext.open && batchContext.batchType === 'S' && <ModalComponent
                visible={batchContext.open && batchContext.batchType === 'S'}
                title={`Multiple Test Cases Were Selected \n
                    Batch File created, you can rename it below`}
                ButtonLabel="Submit"
                createBatch={createSystemBatch}
                onClose={handleModalClose}
                isLoading = {isLoadingg && <PayaptLoader/>}
            />}
        </Box>
    );
}

export default TestCasesContainer;